
.quality-course-container {
    .bodywrap-content {
        padding: 0!important;
        /*min-height: 802px;*/
        box-sizing: border-box;
        margin: 0 auto;
        /*height: calc(100% - 84px) !important;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            padding: 0 40px;
        }
    }
    .container {
        width: 100%;
        padding-right: 40px;
        padding-left: 40px;
        margin-right: auto;
        margin-left: auto;
        background: #fff;
        .course-manage-title {
            /*margin-top: 0;*/
            color: #595959;
            font-size: 18px;
            margin: 20px 0 0;
            width: 1000px;
            font-weight: bold;
            /*margin-left: 0;*/
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
        }
        .res-container {
            display: flex;
            justify-content: start;
            margin-left: 0;
            margin-top: -15px;
            .res-leftarea {
                flex: 1;
                margin: 0;
                padding: 8px 0;
                .sort-btns {
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
                    .sort-btn {
                        font-size: 14px;
                        margin-top: 18px;
                        height: 40px;
                        a {
                            color: #595959;
                            cursor: pointer;
                            vertical-align: bottom;
                            &:first-child {
                                margin-right: 5px;
                            }
                            &:last-child {
                                margin-left: 5px;
                            }
                            &.active {
                                color: #4A90E2;
                            }
                        }
                        span {
                            vertical-align: bottom;
                        }
                    }
                    .input-group {
                        width: 400px;
                        margin-top: 8px;
                        display: flex;
                        justify-content: start;
                        position: relative;
                        .el-input {
                            width: 220px;
                            height: 40px;
                            margin-left: -30px;
                            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
                            border: 1px solid #f5f5f5;
                            padding-left: 36px;
                            border-radius: 5px 0 0 5px;
                            ::v-deep .el-input__inner {
                                border: none;
                                padding: 0;
                            }
                        }
                        i {
                            position: absolute;
                            color: #d6cece;
                            left: -16px;
                            top: 14px;
                            z-index: 20;
                        }
                        .el-button {
                            font-size: 12px;
                            color: #FFF;
                            background-color: #4A90E2;
                            border-color: #4A90E2;
                            width: 60px;
                            height: 40px;
                            border-radius: 0 5px 5px 0;
                        }
                    }
                }
                .res-list {
                    min-height: 645px;
                    margin-left: -5px;
                    .pub-course-item {
                        width: 240px;
                        display: inline-block;
                        height: 200px;
                        margin: 0 20px 15px 5px;
                        .pub-cover {
                            overflow: hidden;
                            width: 100%;
                            height: 144px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .pub-name {
                            color: #333;
                            margin: 15px 0 5px 0;
                        }
                        .text-ellipsis {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .pub-create-time {
                            color: #888;
                        }
                    }
                }
            }
            .col {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
            .res-rightarea {
                width: 240px;
                margin-top: -26px;
                padding: 0 15px;
            }
        }
    }
}
